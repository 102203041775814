@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  #modal button {
    background-color: #ad343e;
    padding: 5px 25px;
    color: white;
    font-size: 18px;
    border: #333 1px solid;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  #modal {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #modal:empty {
    display: none;
  }
  #modal > div {
    background-color: white;
    max-width: 500px;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    background: #faeff0;
  }
  #modal .buttons button {
    display: inline-block;
    margin-right: 15px;
    margin-top: 30px;
  }
  .menu-block {
    background: #fff;
    padding: 0.5rem;

    display: grid;
    grid-gap: 0.5rem;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  }
  .menu-item {
    background-color: #0073cf;
    margin-top: 1rem;
    border-radius: 4px;
    display: grid;
    place-items: center;
    color: #fff;
    font-size: 1.5rem;
    width: 100%;
    height: 15vh;
  }
  .change-svg-color {
    fill: green;
  }
}
